import { post, patch, delete_ } from '@app/utilities/fetchWrapper';
import RoleBinding from './roleBinding';

export default {
  create: async function(universeId: string, userId: string, roleBinding: Partial<RoleBinding>): Promise<RoleBinding> {
    roleBinding.userId = userId;
    roleBinding.universeId = universeId;
    const url = `${COW_API_URL}/role_bindings`;
    return new RoleBinding(await post(url, { body: JSON.stringify(roleBinding) }));
  },
  update: async function(roleBinding: Partial<RoleBinding>): Promise<RoleBinding> {
    const url = `${COW_API_URL}/role_bindings/${roleBinding.id}`;
    return new RoleBinding(await patch(url, { body: JSON.stringify(roleBinding) }));
  },
  delete: async function(roleBindingId: string): Promise<void> {
    return delete_(`${COW_API_URL}/role_bindings/${roleBindingId}`);
  },
};
