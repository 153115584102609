import { EntityType } from '@app/models';
import {
  get, post, patch, delete_,
} from '@app/utilities/fetchWrapper';

export default {
  list: async function(universeId: string): Promise<Array<EntityType>> {
    const entityTypes = await get<Partial<Array<EntityType>>>(`${COW_API_URL}/universes/${universeId}/entity_types`);
    return entityTypes.map((entityType) => new EntityType(entityType));
  },
  create: async function(universeId: string, entityType: Partial<EntityType>): Promise<EntityType> {
    entityType.universeId = universeId;
    const url = `${COW_API_URL}/entity_types`;
    return post(
      url,
      { body: JSON.stringify(entityType) },
    ).then((createdEntityType: any) => new EntityType(createdEntityType));
  },
  update: async function(entityType: Partial<EntityType>): Promise<EntityType> {
    return patch(
      `${COW_API_URL}/entity_types/${entityType.id}`,
      { body: JSON.stringify(entityType) },
    ).then((updatedEntityType: any) => new EntityType(updatedEntityType));
  },
  delete: async function(
    entityTypeId: string,
  ): Promise<EntityType> {
    return delete_(`${COW_API_URL}/entity_types/${entityTypeId}`);
  },
  undoDelete: async function(entityTypeId: string): Promise<EntityType> {
    return new EntityType(
      await patch(
        `${COW_API_URL}/entity_types/deleted/${entityTypeId}`,
        { body: JSON.stringify({ deletedAt: null }) },
      ),
    );
  },
};
