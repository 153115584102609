import FieldRepository from '@app/repositories/fieldRepository';
import RelationshipRequirementRepository from '@app/repositories/relationshipRequirementRepository';
import { Universe, EntityType, RelationshipRequirement } from '@app/models';

export default class Field {
  id: string;

  entityTypeId: string;

  categoryId: string;

  relationshipRequirements: Array<RelationshipRequirement> | undefined;

  userId: string;

  type: string;

  name: string;

  position: number;

  canRelateToAnyEntityType: boolean;

  constructor(obj: any) {
    this.id = obj.id;
    this.entityTypeId = obj.entityTypeId;
    this.categoryId = obj.categoryId;
    this.userId = obj.userId;
    this.type = obj.type;
    this.name = obj.name;
    this.position = obj.position;
    this.canRelateToAnyEntityType = obj.canRelateToAnyEntityType;
    this.relationshipRequirements = obj.relationshipRequirements?.map(
      (relationshipRequirement: any) => new RelationshipRequirement(relationshipRequirement),
    );
  }

  async setReciprocity(universe: Universe, toEntityTypeId: string, reciprocalFieldId: string | null) {
    const requirement = this.getRelationshipRequirement(toEntityTypeId);
    if (requirement) {
      if (requirement.reciprocalFieldId !== reciprocalFieldId) {
        await RelationshipRequirementRepository.update(
          {
            id: requirement.id,
            reciprocalFieldId,
          },
        );
        requirement.reciprocalFieldId = reciprocalFieldId;
      }
    }
    else {
      this.addRelationshipRequirement(toEntityTypeId, reciprocalFieldId);
    }

    // Update related entity type
    const relatedEntityType = universe.getEntityType(toEntityTypeId) as EntityType;
    relatedEntityType.categories.forEach((relatedCategory) => {
      relatedCategory.fields.forEach((relatedField) => {
        const relatedRequirement = relatedField.getRelationshipRequirement(this.entityTypeId);
        if (relatedField.id === reciprocalFieldId) {
          if (relatedRequirement) {
            relatedRequirement.reciprocalFieldId = this.id;
          }
          else {
            relatedField.addRelationshipRequirement(this.entityTypeId, this.id);
          }
        }
        else if (relatedRequirement && relatedRequirement.reciprocalFieldId === this.id) {
          relatedRequirement.reciprocalFieldId = null;
        }
      });
    });
  }

  canBeMadeReciprocalWith(otherField: Field) {
    // When prompting the user to set a reciprocal field, we should only let
    // them choose a field that is not reciprocal to any another field already.
    // That we, people can accidentally break their settings by misclicking the
    // wrong field.

    const requirement = otherField.getRelationshipRequirement(this.entityTypeId);
    return !requirement || !requirement.reciprocalFieldId || requirement.reciprocalFieldId === this.id;
  }

  rename(newName: string) {
    this.name = newName;
    return FieldRepository.update(
      {
        name: this.name,
        id: this.id,
        type: this.type,
      },
    );
  }

  changeCategory(categoryId: string) {
    // TODO should there be a call to the API here?
    this.categoryId = categoryId;
  }

  setCanRelateToAnyEntityType(newValue: boolean) {
    this.canRelateToAnyEntityType = newValue;
    return FieldRepository.update(
      {
        canRelateToAnyEntityType: this.canRelateToAnyEntityType,
        id: this.id,
        type: this.type,
      },
    );
  }

  canRelateTo(toEntityTypeId: string) {
    return this.canRelateToAnyEntityType || !!this.getRelationshipRequirement(toEntityTypeId);
  }

  getRelationshipRequirement(toEntityTypeId: string) {
    return this.relationshipRequirements?.find(
      (requirement: RelationshipRequirement) => requirement.toEntityTypeId === toEntityTypeId,
    );
  }

  async addRelationshipRequirement(toEntityTypeId: string, reciprocalFieldId?: string | null) {
    const requirement = await RelationshipRequirementRepository.create(
      this.id,
      { toEntityTypeId, reciprocalFieldId },
    );
    this.relationshipRequirements?.push(requirement);
  }

  async deleteRelationshipRequirement(toEntityTypeId: string) {
    if (this.relationshipRequirements) {
      const requirement = this.relationshipRequirements.find(
        (r: RelationshipRequirement) => r.toEntityTypeId === toEntityTypeId,
      );
      if (requirement) {
        await RelationshipRequirementRepository.delete(requirement.id);

        const requirementIndex = this.relationshipRequirements.findIndex(
          (other: RelationshipRequirement) => other.id === requirement.id,
        );

        this.relationshipRequirements.splice(requirementIndex, 1);
      }
    }
  }

  isHidden(entityTypes: Array<EntityType>) {
    return !this.isVisible(entityTypes);
  }

  isVisible(entityTypes: Array<EntityType>) {
    if (this.type !== 'Relationship' || this.canRelateToAnyEntityType) {
      return true;
    }

    const entityIdsInUniverse = new Set(entityTypes.map((entityType) => entityType.id));
    return this.relationshipRequirements && this.relationshipRequirements.some(
      (relationshipRequirement) => entityIdsInUniverse.has(relationshipRequirement.toEntityTypeId),
    );
  }
}
