import type { RouteLocationNormalized } from 'vue-router';
import store from '@app/store';
import UniverseRepository from '@app/repositories/universeRepository';
import EntityTypeRepository from '@app/repositories/entityTypeRepository';
import type { Universe } from '@app/models';

async function getUniverses() {
  if (store.state.universes === undefined) {
    const universes = await UniverseRepository.list();
    store.commit('setUniverses', universes);
  }
  return store.state.universes;
}

async function getEntityTypes(universeId: string) {
  if (store.state.entityTypesByUniverseId[universeId] === undefined) {
    const entityTypes = await EntityTypeRepository.list(universeId);
    store.state.entityTypesByUniverseId[universeId] = entityTypes;
  }

  return store.state.entityTypesByUniverseId[universeId];
}

export function updateStoreBasedOnRoute(to: RouteLocationNormalized) {
  if (!store.state.loggedInUser || to.meta.isAdminPage) {
    return;
  }

  let selectedUniverseId = (to.params.universeId || store.state.selectedUniverse?.id) as string | undefined;

  // Some pages should not be associated with a particular universe. For instance, when we go to the
  // universes page, we should reset our selected universe to undefined.
  if (['universes'].find((routeName) => to.name === routeName)) {
    selectedUniverseId = undefined;
    store.commit('setSelectedUniverse', undefined);
  }

  getUniverses().then((universes) => {
    // NOTE: I am not using await here because I don't to block while waiting for getUniverses() to resolve.
    // By not blocking, we are able to start the request for getEntityTypes() concurrently.
    if (universes && selectedUniverseId) {
      const selectedUniverse = universes.find((universe: Universe) => universe.id === selectedUniverseId);
      store.commit('setSelectedUniverse', selectedUniverse);
    }
  });

  if (selectedUniverseId) {
    getEntityTypes(selectedUniverseId);
  }

  if (to.params.entityTypeId !== undefined) {
    store.commit('setSelectedEntityTypeId', to.params.entityTypeId);
  }
  else {
    store.commit('setSelectedEntityTypeId', undefined);
  }
}
