import { put } from '@app/utilities/fetchWrapper';
import { EntityTypePermission, EntityPermission, FieldPermission } from './customRole';
import { AccessLevel } from './accessLevel';

export default {
  setEntityTypePermission: async function(
    customRoleId: string,
    entityTypeId: string,
    accessLevel: AccessLevel,
  ): Promise<EntityTypePermission> {
    const url = `${COW_API_URL}/permissions/entity_types/${entityTypeId}`;
    return put(url, { body: JSON.stringify({ accessLevel, customRoleId }) });
  },
  setEntityPermission: async function(
    customRoleId: string,
    entityId: string,
    accessLevel: AccessLevel,
  ): Promise<EntityPermission> {
    const url = `${COW_API_URL}/permissions/entities/${entityId}`;
    return put(url, { body: JSON.stringify({ accessLevel, customRoleId }) });
  },
  setFieldPermission: async function(
    customRoleId: string,
    entityTypeId: string,
    accessLevel: AccessLevel,
  ): Promise<FieldPermission> {
    const url = `${COW_API_URL}/permissions/fields/${entityTypeId}`;
    return put(url, { body: JSON.stringify({ accessLevel, customRoleId }) });
  },
};
