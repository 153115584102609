import {
  AccessLevel,
  isOwner,
  isAtLeastEditor,
} from '@app/authorization/accessLevel';
import { EntityType } from '@app/models';

export default class BuiltInRole {
  name: string;

  universeWideAccess: AccessLevel;

  constructor(accessLevel: AccessLevel) {
    this.name = accessLevel;
    this.universeWideAccess = accessLevel;
  }

  ownsUniverse(): boolean {
    return isOwner(this.universeWideAccess);
  }

  canEditUniverse(): boolean {
    return isAtLeastEditor(this.universeWideAccess);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canEditEntity(_entity: {id: string, entityTypeId: string}): boolean {
    return isAtLeastEditor(this.universeWideAccess);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canEditAnyPartOfEntity(_entity: {id: string, entityTypeId: string}, _entityType: EntityType): boolean {
    return isAtLeastEditor(this.universeWideAccess);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canEditField(_field: {id: string, entityTypeId: string}): boolean {
    return isAtLeastEditor(this.universeWideAccess);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canEditFieldOnEntity(_entityId: string, _field: {id: string, entityTypeId: string}): boolean {
    return isAtLeastEditor(this.universeWideAccess);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canCreateEntityOfType(_entityTypeId: string): boolean {
    return isAtLeastEditor(this.universeWideAccess);
  }
}
