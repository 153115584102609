import FieldRepository from '@app/repositories/fieldRepository';
import CategoryRepository from '@app/repositories/categoryRepository';
import store from '@app/store';
import { EntityType, Field } from '@app/models';

export default class Category {
  id: string;

  entityTypeId: string;

  name: string;

  fields: Array<Field>;

  position: number;

  constructor(obj: any) {
    this.id = obj.id;
    this.entityTypeId = obj.entityTypeId;
    this.name = obj.name;
    this.position = obj.position;
    this.fields = obj.fields.map(
      (field: any) => new Field(field),
    );
  }

  rename(newName: string) {
    this.name = newName;
    return CategoryRepository.update(
      {
        name: this.name,
        id: this.id,
      },
    );
  }

  getVisibleFields(entityTypes: Array<EntityType>) {
    return this.fields
      .filter((field) => field.isVisible(entityTypes));
  }

  getField(fieldId: string): Field | undefined {
    return this.fields.find((field: Field) => field.id === fieldId);
  }

  async addField(newField: Partial<Field>) {
    return FieldRepository.create(
      this.id,
      newField,
    ).then(
      (field) => {
        this.fields.push(field);
      },
    );
  }

  reorderFields() {
    const order = this.fields.map((field) => field.id);
    return FieldRepository.reorder(this.id, order);
  }

  async deleteField(id: string) {
    await FieldRepository.delete(id);

    const index = this.fields.findIndex((field) => field.id === id);
    const [field] = this.fields.splice(index, 1);
    store.state.toasts.push(
      {
        componentName: 'UndoDeleteToast',
        key: field.id,
        message: `Deleted field "${field.name}"`,
        undoDelete: async() => {
          await FieldRepository.undoDelete(field.id);

          let insertIndex = 0;
          for (const existingField of this.fields) {
            if (existingField.position > field.position) {
              break;
            }
            insertIndex += 1;
          }

          this.fields.splice(insertIndex, 0, field);
        },
      },
    );
  }
}
