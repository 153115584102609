import { Category } from '@app/models';
import {
  post, patch, put, delete_,
} from '@app/utilities/fetchWrapper';

export default {
  create: async function(
    entityTypeId: string,
    category: Partial<Category>,
  ): Promise<Category> {
    category.entityTypeId = entityTypeId;
    return post(
      `${COW_API_URL}/categories`,
      { body: JSON.stringify(category) },
    ).then((createdCategory: any) => new Category(createdCategory));
  },
  update: async function(category: Partial<Category>): Promise<Category> {
    return patch(
      `${COW_API_URL}/categories/${category.id}`,
      { body: JSON.stringify(category) },
    ).then((updatedCategory: any) => new Category(updatedCategory));
  },
  delete: async function(categoryId: string): Promise<void> {
    return delete_(`${COW_API_URL}/categories/${categoryId}`);
  },
  undoDelete: async function(categoryId: string): Promise<Category> {
    return new Category(await patch(
      `${COW_API_URL}/categories/deleted/${categoryId}`,
      { body: JSON.stringify({ deletedAt: null }) },
    ));
  },
  reorder: async function(order: Array<string>): Promise<Array<string>> {
    const newOrder: Array<string> = await put(
      `${COW_API_URL}/categories`,
      { body: JSON.stringify({ ids: order }) },
    );
    return newOrder;
  },
};
