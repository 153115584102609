import { RelationshipRequirement } from '@app/models';
import {
  post, patch, delete_,
} from '@app/utilities/fetchWrapper';

export default {
  create: async function(
    fieldId: string,
    relationshipRequirement: Partial<RelationshipRequirement>,
  ): Promise<RelationshipRequirement> {
    relationshipRequirement.fieldId = fieldId;
    return new RelationshipRequirement(await post(
      `${COW_API_URL}/relationship_requirements`,
      { body: JSON.stringify(relationshipRequirement) },
    ));
  },
  update: async function(
    relationshipRequirement: Partial<RelationshipRequirement>,
  ): Promise<RelationshipRequirement> {
    return new RelationshipRequirement(
      await patch(
        `${COW_API_URL}/relationship_requirements/${relationshipRequirement.id}`,
        { body: JSON.stringify(relationshipRequirement) },
      ),
    );
  },
  delete: async function(
    relationshipRequirementId: string,
  ): Promise<void> {
    await delete_(`${COW_API_URL}/relationship_requirements/${relationshipRequirementId}`);
  },
  undoDelete: async function(
    relationshipRequirementId: string,
  ): Promise<RelationshipRequirement> {
    return new RelationshipRequirement(
      await patch(
        `${COW_API_URL}/relationship_requirements/deleted/${relationshipRequirementId}`,
        { body: JSON.stringify({ deletedAt: null }) },
      ),
    );
  },
};
