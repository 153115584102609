import { createStore } from 'vuex';
import RequestOnceAtATime from '@app/utilities/requestOnceAtATime';
import {
  Universe, EntityType, User,
} from '@app/models';
import type CustomRole from '@app/authorization/customRole';
import type BuiltInRole from '@app/authorization/builtInRole';

interface UndoDeleteToast {
  componentName: 'UndoDeleteToast';
  message: string;
  undoDelete: () => any;
}

interface ErrorMessageToast {
  componentName: 'ErrorMessageToast';
  message: string;
}

interface SuccessMessageToast {
  componentName: 'SuccessMessageToast';
  message: string;
}

type Toast = { key: string } & (
  UndoDeleteToast
  | ErrorMessageToast
  | SuccessMessageToast
);

export interface CowState {
  loggedInUser?: User;
  actingAs: CustomRole | BuiltInRole | undefined;
  editing: boolean;
  selectedEntityTypeId?: string;
  selectedUniverse?: Universe;
  universes?: Array<Universe>;
  entityTypesByUniverseId: Record<string, Array<EntityType>>;
  toasts: Array<Toast>;
  heartbeatSender: RequestOnceAtATime | undefined;
  openModals: string[],
}

function getEmptyState(): CowState {
  return {
    actingAs: undefined,
    editing: true,
    loggedInUser: undefined,
    selectedEntityTypeId: undefined,
    selectedUniverse: undefined,
    universes: undefined,
    entityTypesByUniverseId: {},
    toasts: [],
    heartbeatSender: undefined,
    openModals: [] as string[], // Array of open modal IDs, in order of opening
  };
}

const store = createStore({
  state: {
    ...getEmptyState(),
  },
  mutations: {
    setSelectedUniverse(state: CowState, universe: Universe) {
      state.selectedUniverse = universe;
    },
    setLoggedInUser(state: CowState, loggedInUser: User) {
      state.loggedInUser = loggedInUser;
      if (state.heartbeatSender) {
        state.heartbeatSender.send();
      }
    },
    setSelectedEntityTypeId(state: CowState, id: string) {
      state.selectedEntityTypeId = id;
    },
    setUniverses(state: CowState, universes: Array<Universe>) {
      state.universes = universes;
    },
    setHeartbeatSender(state: CowState, heartbeatSender: RequestOnceAtATime) {
      state.heartbeatSender = heartbeatSender;
    },
    addUniverse(state: CowState, universe: Universe) {
      if (state.universes !== undefined) {
        state.universes.push(universe);
      }
    },
    removeUniverse(state: CowState, universe: Universe) {
      if (state.universes) {
        const index = state.universes.indexOf(universe);
        state.universes.splice(index, 1);
      }
    },
    pushAlertMessage(state: CowState, content: string) {
      state.toasts.push({
        key: content,
        componentName: 'ErrorMessageToast',
        message: content,
      });
    },
    pushSuccessMessage(state: CowState, content: string) {
      state.toasts.push({
        key: content,
        componentName: 'SuccessMessageToast',
        message: content,
      });
    },
    clearState(state: CowState) {
      const newEmptyState = getEmptyState();
      Object.assign(state, newEmptyState);
    },
    toggleEditing(state: CowState) {
      state.editing = !state.editing;
    },
    addOpenModal(state: CowState, modalId: string) {
      state.openModals.push(modalId);
    },
    removeOpenModal(state: CowState, modalId: string) {
      state.openModals = state.openModals.filter((id) => id !== modalId);
    },
  },
});

export default store;
