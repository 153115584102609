import UniverseRepository from '@app/repositories/universeRepository';
import EntityTypeRepository from '@app/repositories/entityTypeRepository';
import ImageRepository from '@app/repositories/imageRepository';
import store from '@app/store';
import Roles from '@app/authorization/roles';
import CustomRole from '@app/authorization/customRole';
import BuiltInRole from '@app/authorization/builtInRole';
import RoleBinding from '@app/authorization/roleBinding';
import roleBindingApiClient from '@app/authorization/roleBindingApiClient';
import { ownerPermission, nonePermission } from '@app/authorization/accessLevel';
import roleApiClient from '@app/authorization/roleApiClient';
import {
  PremiumFeature, EntityType, AbridgedEntity, User, Image,
} from '.';

export default class Universe {
  id: string;

  name: string;

  description: object;

  userId: string;

  images: Image[];

  roleBindings: Array<RoleBinding>;

  customRoles: Array<CustomRole>;

  premiumFeatures: Array<PremiumFeature>;

  constructor(obj: any) {
    this.id = obj.id;
    this.name = obj.name;
    this.description = obj.description;
    this.userId = obj.userId;
    this.roleBindings = obj.roleBindings?.map((binding: any) => new RoleBinding(binding)) || [];
    this.customRoles = obj.customRoles?.map((role: any) => new CustomRole(role)) || [];
    this.premiumFeatures = obj.premiumFeatures;
    this.images = obj.images?.map((image: any) => new Image(image)) || [];
  }

  async rename(newName: string): Promise<void> {
    return UniverseRepository.update(
      {
        name: newName,
        id: this.id,
      },
    ).then((updatedUniverse: Universe) => {
      this.name = updatedUniverse.name;
    });
  }

  async setDescription(newDescription: object): Promise<void> {
    return UniverseRepository.update(
      {
        description: newDescription,
        id: this.id,
      },
    ).then((updatedUniverse: Universe) => {
      this.description = updatedUniverse.description;
    });
  }

  isOwnedBy(user: User): boolean {
    return this.roleBindings.some(
      (binding) => binding.userId === user.id && binding.builtInRole === ownerPermission,
    );
  }

  async delete() {
    await UniverseRepository.delete(this.id);
    store.commit('removeUniverse', this);

    store.state.toasts.push(
      {
        componentName: 'UndoDeleteToast',
        key: this.id,
        message: `Deleted ${this.name}`,
        undoDelete: async() => {
          await UniverseRepository.undoDelete(this.id);
          store.commit('addUniverse', this);
        },
      },
    );
  }

  getEntityType(entityTypeId: string): EntityType | undefined {
    const entityTypes = store.state.entityTypesByUniverseId[this.id];
    return entityTypes.find((entityType: EntityType) => entityType.id === entityTypeId);
  }

  async addEntityType(entityTypeRequest: Partial<EntityType>): Promise<EntityType> {
    const entityTypes = store.state.entityTypesByUniverseId[this.id];
    const entityType = await EntityTypeRepository.create(this.id, entityTypeRequest);
    await entityType.createCategory({ name: 'Overview' });
    entityTypes.push(entityType);
    return entityType;
  }

  async addExistingEntityType(entityTypeId: string): Promise<void> {
    await this.includeEntityTypes([entityTypeId]);
  }

  async reorderEntityTypes() {
    const entityTypes = store.state.entityTypesByUniverseId[this.id];
    const entityTypeOrder = entityTypes.map((entityType) => entityType.id);
    return UniverseRepository.reorderEntityTypes(this.id, entityTypeOrder);
  }

  async deleteEntityType(entityTypeId: string, countOfEntitiesAffected?: number) {
    await EntityTypeRepository.delete(entityTypeId);

    const entityTypes = store.state.entityTypesByUniverseId[this.id];
    const index = entityTypes.findIndex((entityType: EntityType) => entityType.id === entityTypeId);
    const [entityType] = entityTypes.splice(index, 1);

    let deletedMessage = `Removed ${entityType.pluralName} from ${this.name}`;
    if (countOfEntitiesAffected) {
      const name = countOfEntitiesAffected === 1 ? entityType.name : entityType.pluralName;
      deletedMessage += ` causing ${countOfEntitiesAffected} ${name} to be deleted`;
    }

    store.state.toasts.push(
      {
        componentName: 'UndoDeleteToast',
        key: entityType.id,
        message: deletedMessage,
        undoDelete: async() => {
          await EntityTypeRepository.undoDelete(entityType.id);

          let insertIndex = 0;
          for (const existingEntityType of entityTypes) {
            if (existingEntityType.position > entityType.position) {
              break;
            }
            insertIndex += 1;
          }

          entityTypes.splice(insertIndex, 0, entityType);
        },
      },
    );
  }

  async includeEntityTypes(entityTypeIds: Array<string>) {
    store.state.entityTypesByUniverseId[this.id] = (await UniverseRepository.includeEntityTypes(this.id, entityTypeIds))
      .map((entityType: any) => new EntityType(entityType));
  }

  async addImage(file: File): Promise<void> {
    const image = await ImageRepository.addUniverseImage(this.id, file);
    this.images.push(image);
  }

  async addImageByUrl(url: string): Promise<void> {
    const image = await ImageRepository.addUniverseImageByUrl(this.id, url);
    this.images.push(image);
  }

  getImageUrl(): string | undefined {
    if (this.images.length > 0) {
      return `${COW_USER_IMAGE_URL}/${this.images[0].id}.${this.images[0].extension}`;
    }
  }

  getImageUrls(): string[] {
    const urls: string[] = [];
    this.images.forEach((image: Image) => {
      urls.push(`${COW_USER_IMAGE_URL}/${image.id}.${image.extension}`);
    });

    return urls;
  }

  async deleteImage(image: Image): Promise<void> {
    return ImageRepository.deleteUniverseImage(this.id, image.id).then(() => {
      const index = this.images.findIndex((candidateImage) => candidateImage.id === image.id);
      this.images.splice(index, 1);
      store.state.toasts.push(
        {
          componentName: 'UndoDeleteToast',
          key: image.id,
          message: `Deleted image for "${this.name}"`,
          undoDelete: async() => {
            await ImageRepository.undoDeleteUniverseImage(this.id, image.id);

            let insertIndex = 0;
            for (const existingImage of this.images) {
              if (existingImage.position > image.position) {
                break;
              }
              insertIndex += 1;
            }

            this.images.splice(insertIndex, 0, image);
          },
        },
      );
    });
  }

  isPremiumFeatureEnabled(name: string) {
    // Return true only if premium feature is explicitly set to true
    return !!this.premiumFeatures.find((premiumFeature) => premiumFeature.name === name && premiumFeature.enabled);
  }

  async configurePremiumFeature(name: string, enabled: boolean) {
    const premiumFeature = await UniverseRepository.configurePremiumFeature(this.id, name, enabled);

    const oldConfigurationIndex = this.premiumFeatures.findIndex(
      (existingConfiguration) => existingConfiguration.name === name,
    );
    if (oldConfigurationIndex !== -1) {
      this.premiumFeatures.splice(oldConfigurationIndex, 1);
    }
    this.premiumFeatures.push(premiumFeature);
  }

  async searchTags(query: string): Promise<Array<string>> {
    return UniverseRepository.searchTags(this.id, query);
  }

  async getMetadata() {
    return UniverseRepository.getMetadata(this.id);
  }

  getEntityTypeOf(entity: AbridgedEntity): EntityType {
    const entityTypes = store.state.entityTypesByUniverseId[this.id];
    const typeIndex = entityTypes.findIndex(
      (entityType: EntityType) => entityType.id === entity.entityTypeId,
    );
    // It is up to the caller to ensure that the entity is from this universe
    // `as EntityType` will prevent type checking from finding a mistake
    return entityTypes[typeIndex];
  }

  getRole(customRoleId: string): CustomRole | undefined {
    return this.customRoles.find((role) => role.id === customRoleId);
  }

  getRoles(user: User): Roles {
    const roles = new Roles();
    for (const binding of this.roleBindings) {
      if (binding.userId === user.id) {
        if (binding.builtInRole !== nonePermission) {
          roles.add(new BuiltInRole(binding.builtInRole));
        }
        else {
          for (const role of this.customRoles) {
            if (binding.customRoleId === role.id) {
              roles.add(role);
              break;
            }
          }
        }
      }
    }
    return roles;
  }

  async createRole(name: string) {
    const customRole = await roleApiClient.create(this.id, { name });
    this.customRoles.push(customRole);
  }

  async deleteRole(customRoleId: string) {
    const index = this.customRoles.findIndex((role) => role.id === customRoleId);
    const role = this.customRoles[index];

    await roleApiClient.delete(role.id);
    this.customRoles.splice(index, 1);

    store.state.toasts.push(
      {
        componentName: 'UndoDeleteToast',
        key: role.id,
        message: `Deleted custom role "${role.name}"`,
        undoDelete: async() => {
          await roleApiClient.undoDelete(role.id);
          this.customRoles.push(role);
        },
      },
    );
  }

  async createRoleBinding(userId: string, binding: Partial<RoleBinding>) {
    this.roleBindings.push(await roleBindingApiClient.create(this.id, userId, binding));
  }

  async removeRoleBinding(roleBindingId: string) {
    const index = this.roleBindings.findIndex((binding) => binding.id === roleBindingId);
    const binding = this.roleBindings[index];
    this.roleBindings.splice(index, 1);
    await roleBindingApiClient.delete(binding.id);
  }

  getRoleBindingsByCustomRole(customRoleId: string): Array<RoleBinding> {
    return this.roleBindings.filter((binding) => binding.customRoleId === customRoleId);
  }

  getRoleBindingsByUser(userId: string): Array<RoleBinding> {
    return this.roleBindings.filter((binding) => binding.userId === userId);
  }
}
