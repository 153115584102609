import {
  get, post, patch, delete_,
} from '@app/utilities/fetchWrapper';
import CustomRole from './customRole';

export default {
  list: async function(universeId: string): Promise<Array<CustomRole>> {
    const url = `${COW_API_URL}/universes/${universeId}/custom_roles`;
    const customRoles: Array<Partial<CustomRole>> = await get(url);
    return customRoles.map((customRole) => new CustomRole(customRole));
  },
  create: async function(universeId: string, customRole: Partial<CustomRole>): Promise<CustomRole> {
    customRole.universeId = universeId;
    return new CustomRole(await post(
      `${COW_API_URL}/custom_roles`,
      { body: JSON.stringify(customRole) },
    ));
  },
  update: async function(customRoleId: string, customRole: Partial<CustomRole>): Promise<CustomRole> {
    return new CustomRole(await patch(
      `${COW_API_URL}/custom_roles/${customRoleId}`,
      { body: JSON.stringify(customRole) },
    ));
  },
  delete: async function(customRoleId: string): Promise<void> {
    return delete_(`${COW_API_URL}/custom_roles/${customRoleId}`);
  },
  undoDelete: async function(customRoleId: string): Promise<void> {
    return patch(
      `${COW_API_URL}/custom_roles/deleted/${customRoleId}`,
      { body: JSON.stringify({ deletedAt: null }) },
    );
  },
};
