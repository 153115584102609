import { Field } from '@app/models';
import {
  post, put, patch, delete_,
} from '@app/utilities/fetchWrapper';

export default {
  create: async function(categoryId: string, field: Partial<Field>): Promise<Field> {
    field.categoryId = categoryId;
    return post(
      `${COW_API_URL}/fields`,
      { body: JSON.stringify(field) },
    ).then((createdField: any) => new Field(createdField));
  },
  update: async function(field: Partial<Field>): Promise<Field> {
    return patch(
      `${COW_API_URL}/fields/${field.id}`,
      { body: JSON.stringify(field) },
    ).then((udpatedField: any) => new Field(udpatedField));
  },
  delete: async function(fieldId: string): Promise<void> {
    await delete_(`${COW_API_URL}/fields/${fieldId}`);
  },
  undoDelete: async function(fieldId: string): Promise<Field> {
    return new Field(
      await patch(
        `${COW_API_URL}/fields/deleted/${fieldId}`,
        { body: JSON.stringify({ deletedAt: null }) },
      ),
    );
  },
  reorder: async function(categoryId: string, order: Array<string>): Promise<Array<string>> {
    return put(
      `${COW_API_URL}/categories/${categoryId}/fields`,
      { body: JSON.stringify({ ids: order }) },
    );
  },
  changeCategories: async function(categoryId: string, fieldId: string) {
    return patch(
      `${COW_API_URL}/fields/${fieldId}`,
      { body: JSON.stringify({ categoryId }) },
    );
  },
};
